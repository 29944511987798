"use client";

import { WebNavigationComponent, WebNavigationMenu } from "@/util/query/navigation-query";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { DisplayAttributes, DisplayMenuList } from "../nav-ulife-client-component";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils";

type Props = {
    webNavigation: WebNavigationComponent | null;
};

export const BusinessNav = ({ webNavigation }: Props) => {
    const currentPath = usePathname();

    if (!webNavigation) {
        return null;
    }

    return (
        <NavigationMenu.Root className="flex justify-center items-center mx-auto gap-4">
            <NavigationMenu.List className="flex flex-row justify-center gap-4 whitespace-nowrap">
                {webNavigation.menuList.map((menu: WebNavigationMenu) =>
                    menu.subMenuList?.length > 0 ? (
                        <DisplayMenuList key={menu.id} menu={menu} />
                    ) : (
                        <DisplayAttributes key={menu.id} menu={menu} />
                    ),
                )}
            </NavigationMenu.List>

            <div className="ViewportPosition">
                <NavigationMenu.Viewport className="NavigationMenuViewport" />
            </div>
            {/* <Link href={"/business/toolbox"}>
                <Button
                    className={cn(
                        "h-[31px] text-sm",
                        currentPath === "/business/toolbox" ? "bg-[#999999]" : "bg-[#333333]",
                    )}>
                    Toolbox
                </Button>
            </Link> */}
        </NavigationMenu.Root>
    );
};
