"use client";

import { useTranslation } from "@/app/i18n/client";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { usePathname, useRouter } from "next/navigation";
import { setCookie } from "cookies-next";
import { cookieName } from "@/app/i18n/settings";

const LanguageSelection = () => {
    const pathName = usePathname();
    const router = useRouter();
    const { lang } = useTranslation();

    const handleOnValueChanged = (value: string) => {
        setCookie(cookieName, value);
        router.push(pathName);
        // router.refresh();
        window.location.reload();
    };

    return (
        <Select value={lang} onValueChange={handleOnValueChanged}>
            <SelectTrigger className="w-[220px] bg-transparent border-white rounded-full h-10 px-5">
                <SelectValue placeholder="Select Language" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="th">ภาษาไทย</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export default LanguageSelection;
