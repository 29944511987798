"use client";

import { WebNavigationComponent, WebNavigationMenu } from "@/util/query/navigation-query";
import { DisplayAttributesVerical, DisplayMenuListVerical } from "../nav-vertical-client-component";

type Props = {
    webNavigation: WebNavigationComponent | null;
};

export const VerticalNav = ({ webNavigation }: Props) => {
    if (!webNavigation) {
        return null;
    }

    return (
        <div className="flex flex-col w-full justify-start items-start gap-2">
            <div className="flex flex-col w-full justify-start gap-2 whitespace-nowrap ">
                {webNavigation.menuList.map((menu: WebNavigationMenu, index) =>
                    menu.subMenuList?.length > 0 ? (
                        <DisplayMenuListVerical key={`DisplayMenuListVerical_${menu.id}`} menu={menu} index={index} />
                    ) : (
                        <DisplayAttributesVerical
                            key={`DisplayAttributesVerical_${menu.id}`}
                            menu={menu}
                            index={index}
                        />
                    ),
                )}
            </div>
        </div>
    );
};
