import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";

type Props = {
    href: string;
    [key: string]: any;
};

export const NavLink = ({ href, ...props }: Props) => {
    const currentPath = usePathname();

    return (
        <Link
            href={href}
            className={cn(
                "font-light text-sm text-c4 hover:text-c2 self-center",
                currentPath == href ? "text-c2 font-semibold" : "",
            )}
            {...props}
        />
    );
};
