"use client";

import { WebNavigationComponent, WebNavigationMenu } from "@/util/query/navigation-query";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { DisplayAttributes, DisplayMenuList } from "../nav-ulife-client-component";
import { useState } from "react";

type Props = {
    webNavigation: WebNavigationComponent | null;
};

export const ShoppingNav = ({ webNavigation }: Props) => {
    const [value, setValue] = useState("");

    if (!webNavigation) {
        return null;
    }

    return (
        <NavigationMenu.Root
            value={value}
            onValueChange={setValue}
            className="flex justify-center items-center mx-auto gap-4">
            <NavigationMenu.List className="flex flex-row justify-center gap-4 whitespace-nowrap ">
                {webNavigation.menuList.map((menu: WebNavigationMenu) =>
                    menu.subMenuList?.length > 0 ? (
                        <DisplayMenuList key={menu.id} menu={menu} />
                    ) : (
                        <DisplayAttributes key={menu.id} menu={menu} />
                    ),
                )}
            </NavigationMenu.List>

            <div className="ViewportPosition">
                <NavigationMenu.Viewport className="NavigationMenuViewport" />
            </div>
        </NavigationMenu.Root>
    );
};
